import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Button,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
} from '@mui/material';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Swal from 'sweetalert2';
import axios from 'axios';

const MyOrders = () => {
    const auth = useAuthUser();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [trackOpen, setTrackOpen] = useState(false);
    const [trackingDetails, setTrackingDetails] = useState(null);

    const handleGetData = async () => {
        const payload = {
            user_id: auth?.uid,
        };

        try {
            const response = await fetch('https://automation.sai247.in/api/orderdata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            setData(result.data);
        } catch (error) {
            console.error('Error fetching order data:', error);
        }
    };

    useEffect(() => {
        handleGetData();
    }, []);

    const handleOpenModal = (order) => {
        setSelectedOrder(order);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedOrder(null);
    };

    const handleCancelOrder = async (orderId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const payload = { id: orderId };

                try {
                    const response = await fetch('https://automation.sai247.in/api/cancelorder', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    const result = await response.json();

                    if (result.status) {
                        handleGetData();
                        Swal.fire('Canceled!', result.message, 'success');
                    } else {
                        Swal.fire('Failed', result.message, 'error');
                    }
                } catch (error) {
                    console.error('Error cancelling order:', error);
                    Swal.fire('Error', 'An error occurred while cancelling the order.', 'error');
                }
            }
        });
    };


    const AuthToken = async () => {
        const url = 'https://apis-sandbox.fedex.com/oauth/token';
        const client_id = 'l78ae4990b604e4522aa1f902cd1c55742';
        const client_secret = '6c36bc5c25b143bfb54a694c4617da92';
        const formData = new URLSearchParams();
        formData.append('grant_type', 'client_credentials');
        formData.append('client_id', client_id);
        formData.append('client_secret', client_secret);
        
    
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL',
                    'Access-Control-Allow-Origin': '*'
                },
            });
            console.log('Success:', response.data);
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    };
    
    


    // Track Order Functionality
    const handleTrackOrder = async () => {
        const token = await AuthToken();
        alert(token)
        // Example: Call an API to get tracking details
        // try {
        //     const response = await fetch(`https://automation.sai247.in/api/trackorder/`);
        //     const result = await response.json();
        //     setTrackingDetails(result.data); // Assuming result.data contains tracking details
        //     setTrackOpen(true);
        // } catch (error) {
        //     console.error('Error fetching tracking details:', error);
        // }
    };

    const handleCloseTrackModal = () => {
        setTrackOpen(false);
        setTrackingDetails(null);
    };

    return (
        <Container style={{ padding: 0 }}>
            <Typography variant="h4" gutterBottom align="center">
                My Orders ({data.length})
            </Typography>
            <List>
                {data.map((order) => (
                    <React.Fragment key={order.id}>
                        <ListItem
                            sx={{
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'center', sm: 'center' },
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    variant="square"
                                    src={`https://automation.sai247.in/public/product/images/${order.product_img}`}
                                    alt={order.product_name}
                                    sx={{ width: 80, height: 80, marginRight: 3, alignSelf: 'center' }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        noWrap
                                        sx={{ contain: 'inline-size' }}
                                    >
                                        {order.product_name}
                                    </Typography>
                                }
                                secondary={`Invoice: ${order.invoice_number} | Price: ₹${order.unit_price} | Quantity: ${order.quantity}`}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons in column on small screens
                                    alignItems: { xs: 'center', sm: 'flex-start' },
                                    marginTop: { xs: 2, sm: 0 }, // Add margin on top in mobile view
                                    justifyContent: { xs: 'center', sm: 'flex-end' },
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#f43f5e',
                                        marginRight: { xs: 0, sm: '10px' }, // No right margin on small screens
                                        marginBottom: { xs: 2, sm: 0 }, // Add margin on bottom in mobile view
                                        width: {
                                            xs: '100%', // Full width for mobile screens
                                            sm: 'auto',  // Auto width for larger screens (tablets and desktops)
                                        },
                                    }}
                                    onClick={() => handleOpenModal(order)}
                                >
                                    View Details
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#42a5f5',
                                        marginRight: { xs: 0, sm: '10px' }, // Adjust margins for small and large screens
                                        marginBottom: { xs: 2, sm: 0 },
                                        width: {
                                            xs: '100%', // Full width for mobile screens
                                            sm: 'auto',  // Auto width for larger screens
                                        },
                                    }}
                                    onClick={() => handleTrackOrder(order.id)}
                                >
                                    Track Order
                                </Button>
                                {order.order_status == 4 ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'gray',
                                            width: {
                                                xs: '100%', // Full width for mobile screens
                                                sm: 'auto',  // Auto width for larger screens
                                            },
                                        }}
                                    >
                                        Declined Order
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'gray',
                                            width: {
                                                xs: '100%', // Full width for mobile screens
                                                sm: 'auto',  // Auto width for larger screens
                                            },
                                        }}
                                        onClick={() => handleCancelOrder(order.id)}
                                    >
                                        Cancel Order
                                    </Button>
                                )}
                            </Box>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>

            {/* Order Details Modal */}
            {selectedOrder && (
                <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                    <DialogTitle>Order Details</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {selectedOrder.product_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    <strong>Invoice Number:</strong> {selectedOrder.invoice_number}
                                </Typography>
                                <Typography>
                                    <strong>Price:</strong> ₹{selectedOrder.unit_price}
                                </Typography>
                                <Typography>
                                    <strong>Delivery Status:</strong>{' '}
                                    {selectedOrder.order_status == 0
                                        ? 'Pending'
                                        : selectedOrder.order_status == 1
                                            ? 'Processing'
                                            : selectedOrder.order_status == 2
                                                ? 'On Delivery'
                                                : selectedOrder.order_status == 3
                                                    ? 'Delivered'
                                                    : selectedOrder.order_status == 4
                                                        ? 'Declined'
                                                        : 'Completed'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    <strong>Quantity:</strong> {selectedOrder.quantity}
                                </Typography>
                                <Typography>
                                    <strong>Order Date:</strong>{' '}
                                    {new Date(selectedOrder.created_at).toLocaleDateString()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Tracking Details Modal */}
            {trackingDetails && (
                <Dialog open={trackOpen} onClose={handleCloseTrackModal} maxWidth="sm" fullWidth>
                    <DialogTitle>Tracking Details</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1">
                            Current Status: {trackingDetails.status}
                        </Typography>
                        <Typography variant="body1">
                            Estimated Delivery: {trackingDetails.estimatedDelivery}
                        </Typography>
                        <Typography variant="body1">
                            Location: {trackingDetails.currentLocation}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTrackModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
};

export default MyOrders;
