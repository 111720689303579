import React from 'react';
import { useState } from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'; // For social icons
import './Contact.css';
import Banner from '../landing/Banner';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic here
    console.log('Form submitted: ', formData);
  };

  return (
    <div>
         <Banner heading={'Get in Touch'} pera={'Reach out to us for any inquiries or collaborations. We’re here to assist you.'} />
    
    <div className="container mt-5"> 
      <div style={{marginTop:'5%',marginBottom:'5%'}} className="row">
        {/* Left Section */}
        <div className="col-md-6">
          <h2>Contact Us Today</h2>
          <p>
            For inquiries, partnerships, or any assistance needed, feel free to contact us. We are always ready to help.
          </p>
          <p><strong>Phone:</strong> +91 7905350134</p>
          <p><strong>Email:</strong> info@gravenautomation.com</p>
          <p><strong>Address:</strong> F38 GROUND FLOOR GANDHI VIHAR NEW DELHI – INDIA – 110009</p>
          <p><strong>Follow Us:</strong></p>
          <div className="social-icons">
            <FaFacebookF className="me-3" style={{ fontSize: '1.5rem' }} />
            <FaLinkedinIn className="me-3" style={{ fontSize: '1.5rem' }} />
            <FaWhatsapp className="me-3" style={{ fontSize: '1.5rem' }} />
            <FaPhoneAlt className="me-3" style={{ fontSize: '1.5rem' }} />
          </div>
        </div>

        {/* Right Section (Form) */}
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name *</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email *</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message *</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-danger">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Contact;
