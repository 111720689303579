import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom"; // Import Link from react-router-dom

function Footer() {
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCountry = data.country_code.toUpperCase();
        setCountryCode(detectedCountry);
      } catch (error) {
        console.error("Error detecting country:", error);
        setCountryCode('IN');
      }
    };
    detectCountry();
  }, []);

  const location = useLocation();

  if (location.pathname === `/${countryCode}/login` || location.pathname === `/${countryCode}/signup`) {
    return null;
  }

  return (
    <footer className="footer mt-1 py-4 bg-dark text-light">
      <div style={{ marginTop: 0 }} className="container">
        <div className="row">
          {/* About Us Section */}
          {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-4 text-center text-md-left">
            <h5 className="text-uppercase mb-3">About Us</h5>
            <p style={{ fontSize: "0.9rem", lineHeight: "1.6" ,color:'#fff',textAlign:'justify'}}>
              Graven Automation Pvt. Ltd. is a leader in delivering automation solutions. We provide innovative and cutting-edge technologies for industries worldwide.
            </p>
          </div> */}

          {/* Useful Links Section */}
          <div className="col-lg-2 col-md-6 col-sm-12 mb-4 text-center">
            <h5 className="text-uppercase mb-3">Useful Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="footer-link">Home</Link></li>
              <li><Link to={`/${countryCode}/about`} className="footer-link">About Us</Link></li>
              <li><Link to={`/${countryCode}/Blog`}className="footer-link">Blog</Link></li>
              <li><Link to={`/${countryCode}/contact`} className="footer-link">Contact Us</Link></li>
            </ul>
          </div>

          {/* Contact Us Section */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4 text-center">
            <h5 className="text-uppercase mb-3">Contact Us</h5>
            <p style={{ fontSize: "0.9rem",color:'#fff' }}>
              Phone: <a href="tel:+917905350134" className="footer-link">+91-7905350134</a><br />
              Email: <a href="mailto:info@gravenautomation.com" className="footer-link">info@gravenautomation.com</a>
            </p>
          </div>

          {/* Social Media Section */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4 text-center">
            <div className="row">
              <div className="col text-center">
                <h6 className="text-uppercase mb-3">Follow Us</h6>
                <div className="d-flex justify-content-center">
                  <a href="/" className="mx-3 footer-social-icon" aria-label="Facebook">
                    <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
                  </a>
                  <a href="/" className="mx-3 footer-social-icon" aria-label="Instagram">
                    <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                  </a>
                  <a href="/" className="mx-3 footer-social-icon" aria-label="Twitter">
                    <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="row mt-4">
          <div className="col text-center">
            <p className="mb-0" style={{ fontSize: "0.85rem", color: "#999" }}>
              Copyright © 2024 Graven Automation PVT LTD. All rights reserved.
            </p>
          </div>
        </div>
      </div>

      {/* Custom Styles */}
      <style jsx>{`
        .footer {
          background-color: #222;
          color: #fff;
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        .footer h5 {
          font-size: 1.1rem;
          font-weight: 600;
          color: #fff;
        }

        .footer-link {
          color: #ccc;
          font-size: 0.9rem;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        .footer-link:hover {
          color: #fff;
          text-decoration: underline;
        }

        .footer-social-icon {
          color: #ccc;
          transition: color 0.3s ease, transform 0.3s ease;
        }

        .footer-social-icon:hover {
          color: #fff;
          transform: scale(1.1);
        }

        @media (max-width: 576px) {
          .footer {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          .footer h5 {
            font-size: 1rem;
          }

          .footer-link {
            font-size: 0.85rem;
          }

          .footer p {
            text-align: center;
          }

          .footer-social-icon {
            margin: 0 1rem;
          }

          .text-center {
            text-align: center !important;
          }

          .text-md-left {
            text-align: center !important;
          }
        }
      `}</style>
    </footer>
  );
}

export default Footer;
