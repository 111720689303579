import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { useMediaQuery } from '@mui/material';


const CheckoutPage = () => {
    const { countryCode, variant_id, productId } = useParams();
    const auth = useAuthUser();
    console.log(productId);
    const isAuthenticated = useIsAuthenticated();
    const history = useHistory();
    useEffect(() => {
        if (!isAuthenticated) {
            history.push(`/${countryCode}/index`);

        }
    }, []);
    const isMobile = useMediaQuery('(max-width:600px)');

    const [billingName, setBillingName] = useState('');
    const [billingPhone, setBillingPhone] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    const [billingCountry, setBillingCountry] = useState(countryCode);
    const [billingState, setBillingState] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingPincode, setBillingPincode] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [amountPayable, setAmountPayable] = useState(272);
    const [paymentMethod, setPaymentMethod] = useState('online');

    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);

    const [product, setProduct] = useState(null)

    const { error, isLoading, Razorpay } = useRazorpay();

    const handleSubmit = async (e) => {
        // e.preventDefault();


        // Fetch API call
        try {
            const response = await axios.post('https://automation.sai247.in/api/productdetails', {

                variant_id: variant_id,
                user_id: ""

            }, {
                headers: {
                    'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
                }
            });
            console.log(response.data.product);
            setProduct(response.data.product)
            // setProduct(response.data.allProducts.data)
            // setData(response.data);


        } catch (error) {
            console.log(error);
            // setError(error.message);
        }
    }
    useEffect(() => {
        handleSubmit();

    }, []);

    const handlePayment = () => {
        if (billingName && billingPhone && billingEmail && billingCountry && billingState && billingCity && billingPincode && billingAddress) {
            const options = {
                key: 'rzp_test_nvxeVlH43xyZaT',
                amount: parseInt(product?.mrp) * 100,
                currency: 'INR',
                name: 'Test Company',
                description: 'Test Transaction',
                handler: (response) => {
                    console.log(response);
                    //   alert('Payment Successful!');
                    handlePaymentOnline();

                },
                prefill: {
                    name: billingName,
                    email: billingEmail,
                    contact: billingPhone,
                },
                theme: {
                    color: '#F37254',
                },
            };

            const razorpayInstance = new Razorpay(options);
            razorpayInstance.open();
        }
    };

    const handlePincodeChange = async (e) => {
        const pincode = e.target.value;
        setBillingPincode(pincode);

        if (pincode.length === 6) { // Check if pincode length is valid
            try {
                const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
                const data = await response.json();
                if (data[0].Status === "Success") {
                    const { State, District } = data[0].PostOffice[0];
                    setStateOptions([State]); // Set the state
                    setCityOptions([District]); // Set the city
                    setBillingState(State);
                    setBillingCity(District);
                } else {
                    setStateOptions([]);
                    setCityOptions([]);
                    alert("Invalid pincode. Please enter a valid one.");
                }
            } catch (error) {
                console.error('Error fetching pincode data:', error);
            }
        }
    };

    const handlePaymentCash = async () => {
        if (billingName != '' && billingPhone != '' && billingEmail != '' && billingCountry != '' && billingState != '' && billingCity != '' && billingPincode != '' && billingAddress != '') {
            const payload = {
                user_id: auth?.uid,
                shipping_address: {
                    billing_name: billingName,
                    billing_phone: billingPhone,
                    billing_email: billingEmail,
                    billing_country: billingCountry,
                    billing_state: billingState,
                    billing_city: billingCity,
                    billing_area: "",
                    billing_pincode: billingPincode,
                    billing_address: billingAddress,
                    billing_address_type: null
                },
                billing_address: {
                    billing_name: billingName,
                    billing_phone: billingPhone,
                    billing_email: billingEmail,
                    billing_country: billingCountry,
                    billing_state: billingState,
                    billing_city: billingCity,
                    billing_area: "",
                    billing_pincode: billingPincode,
                    billing_address: billingAddress,
                    billing_address_type: null
                },
                product_id: productId,
                variant_id: variant_id,
                razorpay_order_id: "order_jhsagmsa"
            };
            console.log(payload);

            try {
                const response = await fetch('https://automation.sai247.in/api/buynow_caseondelivery', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const result = await response.json();
                console.log('Order placed successfully:', result);
                if (result.massege == 'order successfull') {
                    Swal.fire({
                        title: 'Order Successful!',
                        text: 'Your order has been placed successfully.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        // Optionally redirect or perform other actions after the alert
                        history.push(`/${billingCountry}/Index`);
                    });
                }
            } catch (error) {
                console.error('Error placing order:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'There was an issue placing your order. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }

    };
    const handlePaymentOnline = async () => {

        const payload = {
            user_id: auth?.uid,
            shipping_address: {
                billing_name: billingName,
                billing_phone: billingPhone,
                billing_email: billingEmail,
                billing_country: billingCountry,
                billing_state: billingState,
                billing_city: billingCity,
                billing_area: "",
                billing_pincode: billingPincode,
                billing_address: billingAddress,
                billing_address_type: null
            },
            billing_address: {
                billing_name: billingName,
                billing_phone: billingPhone,
                billing_email: billingEmail,
                billing_country: billingCountry,
                billing_state: billingState,
                billing_city: billingCity,
                billing_area: "",
                billing_pincode: billingPincode,
                billing_address: billingAddress,
                billing_address_type: null
            },
            product_id: productId,
            variant_id: variant_id,
            razorpay_order_id: "order_jhsagmsa"
        };

        console.log(payload);

        try {
            const response = await fetch('https://automation.sai247.in/api/buynow_online', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            console.log('Order placed successfully:', result);
            if (result.massege == 'order successfull') {
                Swal.fire({
                    title: 'Order Successful!',
                    text: 'Your order has been placed successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    // Optionally redirect or perform other actions after the alert
                    history.push(`/${billingCountry}/Index`);
                });
            }
        } catch (error) {
            console.error('Error placing order:', error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue placing your order. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }


    };

    return (
        <div style={{ width: '100%', margin: 'auto', paddingTop: '100px', backgroundColor: '#f9f9f9', paddingTop: '100px', paddingRight: 20, paddingLeft: 20 }}>
            <h2 style={{ textAlign: 'center', color: '#f43f5e', fontWeight: 'bold', marginBottom: '20px' }}>Checkout</h2>
            <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', alignItems: 'flex-start', flexWrap: isMobile ? 'wrap' : 'nowrap', marginBottom: isMobile ? 20: 0 }}>
                <div style={{ flexGrow: 1, marginRight: '20px' }}>
                    <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
                        <h4 style={{ marginBottom: '15px', fontSize: '18px', fontWeight: '600', color: '#333' }}>Delivery Address</h4>
                        <input type="text" placeholder="Name" value={billingName} onChange={(e) => setBillingName(e.target.value)} style={inputStyle} />
                        <input type="text" placeholder="Phone" value={billingPhone} onChange={(e) => setBillingPhone(e.target.value)} style={inputStyle} />
                        <input type="email" placeholder="Email" value={billingEmail} onChange={(e) => setBillingEmail(e.target.value)} style={inputStyle} />
                        <input type="text" placeholder="Pincode" value={billingPincode} onChange={handlePincodeChange} style={inputStyle} />
                        <select value={billingState} onChange={(e) => setBillingState(e.target.value)} style={inputStyle}>
                            <option value="">Select State</option>
                            {stateOptions.map((state, index) => (
                                <option key={index} value={state}>{state}</option>
                            ))}
                        </select>
                        <select value={billingCity} onChange={(e) => setBillingCity(e.target.value)} style={inputStyle}>
                            <option value="">Select City</option>
                            {cityOptions.map((city, index) => (
                                <option key={index} value={city}>{city}</option>
                            ))}
                        </select>
                        <textarea placeholder="Full Address" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} style={{ ...inputStyle, height: '100px' }}></textarea>
                    </div>

                    {!isMobile && <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
                        <h4 style={{ marginBottom: '15px', fontSize: '18px', fontWeight: '600', color: '#333' }}>Payment Options</h4>
                        <div style={{ marginBottom: '15px' }}>
                            <label style={{ display: 'block', marginBottom: '10px', fontWeight: '600', fontSize: '16px' }}>
                                <input type="radio" name="paymentMethod" value="online" checked={paymentMethod === 'online'} onChange={(e) => setPaymentMethod(e.target.value)} style={{ marginRight: '10px' }} />
                                Online Payment
                            </label>
                            <label style={{ display: 'block', fontWeight: '600', fontSize: '16px' }}>
                                <input type="radio" name="paymentMethod" value="cod" checked={paymentMethod === 'cod'} onChange={(e) => setPaymentMethod(e.target.value)} style={{ marginRight: '10px' }} />
                                Cash on Delivery
                            </label>
                        </div>
                        <button onClick={() => paymentMethod == 'online' ? handlePayment() : handlePaymentCash()} style={buttonStyle}>Continue</button>
                    </div>}
                </div>

                <div style={priceDetailsStyle}>
                    <h4 style={{ marginBottom: '15px', fontSize: '18px', fontWeight: '600', color: '#333' }}>Price Details</h4>
                    <p>Name : {product?.variant_name}</p>
                    <p>Price (1 item): {product?.mrp}</p>
                    {/* <p>Delivery Charges: FREE</p>
          <p>Platform Fee: ₹3</p> */}
                    <hr />
                    <p><strong>Amount Payable: {product?.mrp}</strong></p>
                    {/* <p style={{ marginTop: '10px', color: '#4CAF50' }}>You will get ₹14 cashback in your card/account</p> */}
                </div>
            </div>
            {isMobile && <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', marginBottom: '20px' }}>
                <h4 style={{ marginBottom: '15px', fontSize: '18px', fontWeight: '600', color: '#333' }}>Payment Options</h4>
                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '10px', fontWeight: '600', fontSize: '16px' }}>
                        <input type="radio" name="paymentMethod" value="online" checked={paymentMethod === 'online'} onChange={(e) => setPaymentMethod(e.target.value)} style={{ marginRight: '10px' }} />
                        Online Payment
                    </label>
                    <label style={{ display: 'block', fontWeight: '600', fontSize: '16px' }}>
                        <input type="radio" name="paymentMethod" value="cod" checked={paymentMethod === 'cod'} onChange={(e) => setPaymentMethod(e.target.value)} style={{ marginRight: '10px' }} />
                        Cash on Delivery
                    </label>
                </div>
                <button onClick={() => paymentMethod == 'online' ? handlePayment() : handlePaymentCash()} style={buttonStyle}>Continue</button>
            </div>}
        </div>
    );
};

// Styles for input fields and buttons
const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '10px',
    fontSize: '14px',
    outline: 'none',
    boxSizing: 'border-box',
};

const buttonStyle = {
    width: '100%',
    padding: '12px',
    backgroundColor: '#f43f5e',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
};

const priceDetailsStyle = {
    width: '300px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
};

export default CheckoutPage;
