import React, { useState } from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'; // For social icons
import Banner from '../landing/Banner';
import './About.css';

function About() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit form logic here
        console.log('Form submitted: ', formData);
    };

    return (
        <div>
            <Banner heading={'Leading Automation Solutions'} pera={'Empowering industries through innovative automation'} />

            <div className="container-fluid mt-1">
                <div style={{ marginTop: '2%', marginBottom: '0%' }} className="row">
                    {/* Left Section */}
                    <div style={{ textAlign: 'center' }} className="col-md-6">
                        <h1>Our Story</h1>
                    </div>
                    {/* Right Section */}
                    <div className="col-md-6">
                        <p>
                            Established in the industrial automation sector, Graven Automation Private has a rich history of pioneering advancements…
                            <br />
                            <br />
                            Having served a diverse clientele ranging from small businesses to large corporations, we have built strong relationships…
                        </p>
                    </div>

                    {/* Image */}
                    <img

                        height="340"
                        width={'100%'}
                        style={{ borderRadius: 10, marginTop: '20px', height: '40vh' }}
                        alt="Graven Automation"
                        src={'https://gravenautomation.com/wp-content/uploads/2024/05/zipwp-image-5800228.jpeg'}
                    />

                    {/* Button */}
                    {/* <div className="col-12 text-center mt-4">
                        <button
                            className="btn btn-primary btn-lg"
                            onClick={() => alert("Let's Work Together!")}
                        >
                            Let's Work Together!
                        </button>
                    </div> */}

                    {/* Transform Your Business Section */}
                    <div className="col-12 text-center mt-5">
                        <h2>Transform Your Business with Graven Automation</h2>
                        <p>Discover how our innovative solutions can propel your business to the next level.</p>
                    </div>

                    <div className="row text-center mt-4">
                        <div className="col-md-4">
                            <img
                                src="https://www.exin.com/app/uploads/2023/02/Automation-image-for-blog-article.jpg"
                                alt="Tailored Solutions"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                            <h4>01. Tailored Solutions</h4>
                            <p>We customize top-tier products to meet your unique requirements.</p>
                        </div>
                        <div className="col-md-4">
                            <img
                                src="https://www.mech-mind.com/Private/NewsImgs/6380525517601540471334021809.webp"
                                alt="Unmatched Service"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                            <h4>02. Unmatched Service</h4>
                            <p>Our commitment to exceeding expectations fosters long-term partnerships.</p>
                        </div>
                        <div className="col-md-4">
                            <img
                                src="https://azadmagazine.com/wp-content/uploads/2023/11/Harnessing-the-Power-of-Industrial-Automation-for-Business-Growth.webp"
                                alt="Reliable Expertise"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                            <h4>03. Reliable Expertise</h4>
                            <p>With a wealth of experience and a focus on innovation, we deliver trusted solutions.</p>
                        </div>
                    </div>

                    {/* Meet Our Team Section */}
                    <div className="col-12 text-center mt-5">
                        <h2>Meet Our Team</h2>
                    </div>

                    {/* Cards for Team Members */}
                    <div className="row text-center mt-4">
                        <div className="col-md-4">
                            <div className="card" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img
                                    src="https://gravenautomation.com/wp-content/uploads/2023/08/team-skip-01-1.jpg"
                                    alt="Team Member 1"
                                    className="card-img-top rounded-circle"
                                    style={{ width: '150px', height: '150px', margin: '20px auto' }}
                                />
                                <div className="card-body">
                                    <h5>Shawn Marks</h5>
                                    <p className="card-text">CEO & Founder</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img
                                    src="https://gravenautomation.com/wp-content/uploads/2023/08/team-skip-02-1.jpg"
                                    alt="Team Member 2"
                                    className="card-img-top rounded-circle"
                                    style={{ width: '150px', height: '150px', margin: '20px auto' }}
                                />
                                <div className="card-body">
                                    <h5>Emma Johnson</h5>
                                    <p className="card-text">CTO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img
                                    src="https://gravenautomation.com/wp-content/uploads/2023/08/team-skip-03.jpg"
                                    alt="Team Member 3"
                                    className="card-img-top rounded-circle"
                                    style={{ width: '150px', height: '150px', margin: '20px auto' }}
                                />
                                <div className="card-body">
                                    <h5>John Smith</h5>
                                    <p className="card-text">Head of Operations</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="why-choose-us-section">
                <h2 className="why-choose-us-title">Who we are ??</h2>
                <p className="why-choose-us-content">
                    At Graven Automation, we are at the forefront of transforming industrial operations through innovative automation technologies. Headquartered in New Delhi - INDIA, we have proudly serving the B2B sector with intelligent automation solutions designed to streamline processes, optimize productivity and reduce operational costs.
                    Our journey began with a vision to bridge the gap between traditional industrial practices and modern technologincal advancements. Today, we stand as a trusted partner to industries ranging from manufacturing and loginstics to energy and beyond. By levaraging cutting-edge tools like robotics, IoT, and advanced control systems, we create solutions that not only meet but exceed our clients' expectations.
                    What sets us apart is our unwavering commitment to customization and adaptability. We understand that no two businesses are the same, and our solutions are tailored to the specific challenges and goals of each client. Whether you're looking to enhance production, omprove safety, or minimize downtime, Graven Automation is your partner in achieving sustainable growth through automation.
                    Deriven by innovation, supported by a team of experienced engineers, and backed by a dedication to quality and service, we are redefining what's possible in industrial automation. As industries evolve, so dowe, ensuring that our clients stay ahead in a rapidly changing landscape.
                </p>
            </div>
            <div style={{ marginBottom: '5%' }} className="hero-container">
                <div className="overlay"></div>
                <div className="hero-content">
                    <h1>Transform Your Business with <span>Graven Automation</span></h1>
                    <p>Take the first step towards enhanced efficiency and productivity. Contact us today to explore our cutting-edge automation solutions.</p>
                    <button className="contact-button">Contact Now</button>
                </div>
            </div>
        </div >
    );
}

export default About;
