import RelatedProduct from "./RelatedProduct";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import "./Product.css";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal"; // Assuming you're using react-bootstrap for modal
import Button from "react-bootstrap/Button"; // For buttons in modal
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Toast, ToastContainer } from 'react-bootstrap';
// import Carousel from 'react-bootstrap/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";

function ProductDetail() {
  // const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  // Accessing data passed via Link
  const { countryCode, variant_id, productId } = useParams();
  console.log(countryCode, variant_id, productId);
  // alert(countryCode)

  function changeRating(newRating) { }

  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");


  const [product, setProduct] = useState(null)
  const [showToast, setShowToast] = useState(false); // State for toast visibility
  const handleSubmit = async (e) => {
    // e.preventDefault();


    // Fetch API call
    try {
      const response = await axios.post('https://automation.sai247.in/api/productdetails', {

        variant_id: variant_id,
        user_id: ""

      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      console.log(response.data.product);
      setProduct(response.data.product)
      // setProduct(response.data.allProducts.data)
      // setData(response.data);


    } catch (error) {
      console.log(error);
      // setError(error.message);
    }
  }

  // alert(auth.users_id)
  // console.log(auth?.uid);

  const AddToCart = async (e) => {
    // e.preventDefault();

    if (isAuthenticated) {
      try {
        const response = await axios.post('https://automation.sai247.in/api/addtocart', {

          variant_id: variant_id,
          user_id: auth?.uid,
          product_id: productId

        }, {
          headers: {
            'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
          }
        });
        console.log(response.status);
        if (response.status) {
          Swal.fire('Success!', 'Product added to cart successfully!', 'success');
        }


      } catch (error) {
        console.log(error);
        // setError(error.message);
      }
    } else {
      Swal.fire('Oops...', 'Please log in to add items to your cart.', 'error');
    }
    // Fetch API call


  }
  const [productlist, setProductlist] = useState([])

  const handleProduct = async (e) => {
    // e.preventDefault();


    // Fetch API call
    try {
      const response = await axios.post('https://automation.sai247.in/api/index', {

        user_id: "",
        countrycode: countryCode

      }, {
        headers: {
          'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
        }
      });
      console.log(response.data.allProducts.data);
      setProductlist(response.data.allProducts.data)
      // setData(response.data);


    } catch (error) {
      console.log(error);
      // setError(error.message);
    }
  }



  const SubmitEnquery = async (e) => {
    // e.preventDefault();

    if (isAuthenticated) {
      // Fetch API call
      if (message != '') {
        const body = {
          user_id: auth?.uid,
          product_id: selectedProducts.map(item => item.id),
          message: message
        }
        console.log(body);
        try {
          const response = await axios.post('https://automation.sai247.in/api/productEnquiry', {
            user_id: auth?.uid,
            product_id: selectedProducts.map(item => item.id),
            message: message
          }, {
            headers: {
              'X-CSRF-Token': 'C30tPucNXm4d6fLdzE0b22YG5kJ2Mg8SMC4wzhnL'
            }
          });

          console.log(response.data);

          if (response.data.success) {
            setMessage2(response.data.message)
            setShowModal(false)
            Swal.fire('Success!', response.data.message, 'success');
            setSelectedProducts([])
            setMessage('')
          } else {
            setMessage2(response.data.message)
            Swal.fire('Error!',  response.data.message, 'error');
            setShowModal(false)
            setSelectedProducts([])
            setMessage('')
          }

          // setShowModal(false)
          // setShowToast(true);
          // setData(response.data);


        } catch (error) {
          console.log(error);
          // setError(error.message);
        }
      } else {
        Swal.fire('Error!', 'Please drop a message.', 'error');
        setMessage2('drop a message')
        // alert()
      }
    } else {
      Swal.fire('Oops...', 'Please log in to add items to your cart.', 'error');
    }
  }
  useEffect(() => {
    handleSubmit();
    handleProduct();
    window.scrollTo(0, 0);
  }, [productId, variant_id, countryCode]);



  const handleAddToEnquiry = (product) => {
    setSelectedProducts([...selectedProducts, product]);
    setShowModal(true);
  };

  const handleRemoveProduct = (product) => {
    setSelectedProducts(selectedProducts.filter(p => p.id !== product.id));
  };



  return (
    <div  style={{ backgroundColor: '#fff', }}>
      <div style={{ height: 0 }}>

      </div>

      <div className="container-fluid mt-5 py-4 px-xl-5 mt-5">
        <ScrollToTopOnMount />
        <div className="row mb-4">
          <div className="col-lg-6">
            {/* <Slider {...settings}>
              {images.map((img, index) => (
                <div key={index}>
                  <img src={img} alt={`slide-${index}`} style={{ width: '100%', height: 400 }} />
                </div>
              ))}
            </Slider> */}
            <div>
              {/* <h2>My Photo Gallery</h2> */}
              <div style={{ height: '500px' }}>
                <Carousel autoPlay interval={5000} transitionTime={100} infiniteLoop>
                  {/* {images.map((img, index) => ( */}
                  {/* <div style={{ height: '350px' }}>
                    <img src={`https://automation.sai247.in/public/product/images/${product?.product_img}`} style={{ width: '100%', height: '100%', objectFit: 'cotain' }} />

                  </div> */}
                  <div style={{ height: '350px' }}>
                    <img src={`https://automation.sai247.in/public/product/images/${product?.gallery_img1}`} style={{ width: '100%', height: '100%', objectFit: 'cotain' }} />

                  </div>
                  <div style={{ height: '350px' }}>
                    <img src={`https://automation.sai247.in/public/product/images/${product?.gallery_img2}`} style={{ width: '100%', height: '100%', objectFit: 'cotain' }} />

                  </div>
                  <div style={{ height: '350px' }}>
                    <img src={`https://automation.sai247.in/public/product/images/${product?.gallery_img3}`} style={{ width: '100%', height: '100%', objectFit: 'cotain' }} />

                  </div>
                  <div style={{ height: '350px' }}>
                    <img src={`https://automation.sai247.in/public/product/images/${product?.gallery_img4}`} style={{ width: '100%', height: '100%', objectFit: 'cotain' }} />

                  </div>
                  {/* ))} */}
                </Carousel>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="d-flex flex-column h-100">
              <h4 className="mb-1">{product?.variant_name}</h4>
              {/* <h4 className="text-muted mb-4">{product?.mrp}</h4> */}
              <dl style={{ marginTop: 10 }} className="row">
                <dt className="col-sm-3"><h4>Price </h4></dt>
                {/* <dt className="col-sm-1"><h4> :</h4></dt> */}
                <dd className="col-sm-8 mb-3"><h4 style={{ color: 'green' }}>{product?.mrp} </h4></dd>
              </dl>
              <dl style={{ marginTop: -10 }} className="row">
                <dt className="col-sm-3"><h4>Availability </h4></dt>
                {/* <dt className="col-sm-1"><h4> :</h4></dt> */}
                <dd className="col-sm-8 mb-3"><h4 style={{ color: 'green' }}>{product?.stock} in stock</h4></dd>
              </dl>


              <h4 className="mb-0">Details</h4>
              <hr />
              <dl className="row">
                {/* <h4 className="mb-0">Description</h4> */}

                <p className="lead flex-shrink-0">
                  <small>
                    <div
                      dangerouslySetInnerHTML={{ __html: product?.description }}
                    ></div>
                    {/* {product?.description} */}
                  </small>
                </p>
              </dl>

              <div className="row g-3 mb-4">
                <div className="col">
                  <button onClick={() => AddToCart()} className="btn btn-outline-dark py-2 w-100">
                    Add to cart
                  </button>
                </div>
                <div className="col">
                  {/* <Link to={`/${countryCode}/products/${variant_id}/${id}`} className="btn btn-outline-dark custom-btn">
              View Details
            </Link> */}
                  {isAuthenticated ? <button style={{ backgroundColor: '#e11d48', color: "#fff" }} className="btn  py-2 w-100">
                    <Link style={{ color: 'white' }} to={
                       `/${countryCode}/products/${variant_id}/${productId}/checkoutPage`
                    } >
                    Buy Now
                  </Link></button> : <button onClick={() => alert('Please log in to Buy this item.')} style={{ backgroundColor: '#e11d48', color: "#fff" }} className="btn  py-2 w-100">

                  Buy Now
                </button>}
              </div>
            </div>
            <div className="col">
              <button onClick={() => handleAddToEnquiry(product)} style={{ backgroundColor: '#e11d48', color: "#fff" }} className="btn  py-2 w-100 mb-3">Add to Enquiry</button>
            </div>
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-4">
          <hr />
          <h4 className="text-muted my-4">Related products</h4>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 g-3">
            {productlist.map((item, i) => {
              return (
                <RelatedProduct key={i} countryCode={countryCode} data={item} percentOff={i % 2 === 0 ? 15 : null} />
              );
            })}
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{message2}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add to Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Selected Products</h5>
          <ul className="list-unstyled">
            {selectedProducts.map((product) => (
              <li className="my-3" key={product.id}>
                <div className="d-flex align-items-center">
                  {product?.image && <img
                    src={`https://automation.sai247.in/public/product/images/${product?.image}`}
                    alt={product.name}
                    className="img-thumbnail"
                    style={{ width: '60px', height: '60px', marginRight: '15px' }}
                  />}
                  {product?.product_img && <img
                    src={`https://automation.sai247.in/public/product/images/${product?.product_img}`}
                    alt={product.variant_name}
                    className="img-thumbnail"
                    style={{ width: '60px', height: '60px', marginRight: '15px' }}
                  />}
                  <div className="flex-grow-1">
                    <h6>{product.variant_name}</h6>
                    <p className="mb-1">{product.mrp} </p>
                  </div>
                  <Button variant="danger" onClick={() => handleRemoveProduct(product)}>
                    Remove
                  </Button>
                </div>
              </li>
            ))}
          </ul>

          <textarea
            className="form-control my-3"
            rows="3"
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <h5>All Products</h5>
          <ul className="list-unstyled">
            {productlist.map((product) => (
              <li className="my-3" key={product.id}>
                <div className="d-flex align-items-center">
                  <img
                    src={`https://automation.sai247.in/public/product/images/${product?.image}`}
                    alt={product.name}
                    className="img-thumbnail"
                    style={{ width: '60px', height: '60px', marginRight: '15px' }}
                  />
                  <div className="flex-grow-1">
                    <h6>{product.name}</h6>
                    <p className="mb-1">{product.mrp} Ks</p>
                  </div>
                  <Button
                    variant="primary"
                    onClick={() => handleAddToEnquiry(product)}
                    disabled={selectedProducts.some((p) => p.id === product.id)}
                  >
                    Add to Enquiry
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => SubmitEnquery()}>
            Submit Enquiry
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
    </div >
  );
}

export default ProductDetail;
