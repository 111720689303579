import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import './Header.css';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { Navbar, Nav, Container, Dropdown, Button, NavLink } from 'react-bootstrap';
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

function Header({ countryCode }) {
  const isMobile = useMediaQuery('(max-width:650px)');
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  const auth = useAuthUser();
  const country = countryCode;
  const location = useLocation();

  // Logout after 1 hour of login
  useEffect(() => {
    const expiryTime = localStorage.getItem('expiryTime');
    if (expiryTime) {
      const now = new Date().getTime();
      const timeLeft = expiryTime - now;

      if (timeLeft > 0) {
        // Set a timeout to auto logout when the session expires
        const timer = setTimeout(() => {
          signOut();
        }, timeLeft);

        return () => clearTimeout(timer); // Clear timeout on component unmount
      } else {
        // Session has already expired
        signOut();
      }
    }
  }, []);

  // Function to open or close the drawer (toggle)
  function toggleDrawer() {
    setOpenedDrawer(!openedDrawer); // Toggle the drawer state
  }

  // Force drawer to close when a navigation link is clicked (on mobile)
  function closeDrawer() {
    setOpenedDrawer(false); // Close the drawer
  }

  // Close drawer when location (route) changes
  useEffect(() => {
    setOpenedDrawer(false); // Always close drawer on route change
  }, [location]);

  // Hide header on login/signup pages
  if (location.pathname === `/${countryCode}/login` || location.pathname === `/${countryCode}/signup`) {
    return null;
  }

  return (
    <header>
      <Navbar expand="lg" fixed="top" style={{backgroundColor:'#fff'}} className="shadow-sm">
        <Container fluid>
          <Navbar.Brand as={Link} to={`/${country}/index`} onClick={closeDrawer}>
            <img
              src="https://gravenautomation.com/wp-content/uploads/2024/05/cropped-GROVEN-removebg-preview-40x39.png"
              width={40}
              height={40}
              alt="Graven Automation"
            />
            <span className="ms-2 h4 text-black">Graven Automation</span>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="navbar-nav"
            onClick={toggleDrawer}
            aria-expanded={openedDrawer}
          />

          <Navbar.Collapse in={openedDrawer} id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to={`/${country}/index`} className="text-black" onClick={closeDrawer}>
                Home
              </Nav.Link>
              <Nav.Link as={Link} to={`/${country}/products`} className="text-black" onClick={closeDrawer}>
                Products
              </Nav.Link>
              <Nav.Link as={Link} to={`/${country}/contact`} className="text-black" onClick={closeDrawer}>
                Contact
              </Nav.Link>
              <Nav.Link as={Link} to={`/${country}/about`} className="text-black" onClick={closeDrawer}>
                About
              </Nav.Link>
              <Nav.Link as={Link} to={`/${country}/Blog`} className="text-black" onClick={closeDrawer}>
                Blog
              </Nav.Link>

              {isAuthenticated ? (
                <Nav.Item>
                  <Dropdown>
                    <Dropdown.Toggle as={NavLink} className="text-black">My Account</Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item as={Link} to={`/${country}/Profile`} onClick={closeDrawer}>
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => { signOut(); window.location.reload(); }}>
                        Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              ) : (
                <Nav.Link as={Link} to={`/${country}/login`} className="text-black" onClick={closeDrawer}>
                  Login
                </Nav.Link>
              )}

              {isAuthenticated ? (
                <Nav.Link as={Link} to={`/${country}/cart`} onClick={closeDrawer}>
                  <Button variant="outline-dark" className="ms-3">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" /> My Cart
                  </Button>
                </Nav.Link>
              ) : (
                <Nav.Link as={Link} to={`/${country}/login`} onClick={closeDrawer}>
                  <Button variant="outline-dark" className="ms-3">
                    <FontAwesomeIcon icon={faShoppingCart} className="me-2" /> My Cart
                  </Button>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
