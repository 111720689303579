import ProductDetail from "./products/detail/ProductDetail";
import { Switch, Route, Redirect, BrowserRouter as Router, useLocation } from "react-router-dom";
import Landing from "./landing/Landing";
import Contact from "./contact/Contact";
import About from "./about/About";
import HomeIndex from "./mainscreen/Index";
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import Login from "./Login/Login";
import { useState, useEffect } from "react";
import MyCart from "./mycart/MyCart";
import './App.css';
import Blog from "./blog/Blog";
import Profile from "./myprofile/Profile";
import Signup from "./Login/Signup";
import Header from "./template/Header";  // Import Header separately
import Footer from "./template/Footer";  // Import Footer separately
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import LandingCategorywise from "./projectCategoryWise/LandingCategorywise";
import Industries from "./Industries/Industries";


function App() {
  // Initialize authentication store
  const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:', // Make cookies secure if using HTTPS
  });

  // State to hold detected country code
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const detectCountry = async () => {
      try {
        // Fetch the user's country information from ipapi.co
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCountry = data.country_code.toUpperCase(); // Convert country code to uppercase
        setCountryCode(detectedCountry); // Set the detected country code
      } catch (error) {

        setCountryCode('IN'); // Fallback to default country (e.g., India)
      }
    };

    detectCountry(); // Run the function on component mount
  }, []);

  // Get the current route using the useLocation hook
  const location = useLocation();

  // Define paths where the Header and Footer should not be displayed (e.g., login, signup)
  const hideHeaderFooterRoutes = ["/login", "/signup"];

  // Check if the current path matches any of the hideHeaderFooterRoutes
  const showHeaderFooter = !hideHeaderFooterRoutes.some(route => location.pathname.includes(route));

  // Show loading screen while country is being detected
  if (!countryCode) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <div className="loading-text">Loading...</div>
      </div>
    );
  }

  return (
    <AuthProvider store={store}>
      <Router>
        {/* Conditionally render Header and Footer based on current route */}
        {showHeaderFooter && <Header countryCode={countryCode} />}

        {/* App routes */}
        <Switch>
          {/* Redirect root path to the country-specific login page */}
          <Route path="/" exact>
            <Redirect to={`/${countryCode}/index`} />
          </Route>

          {/* Country-specific dynamic routes */}
          <Route path="/:country/index" exact>
            <HomeIndex />
          </Route>
          <Route path="/:country/Blog" exact>
            <Blog />
          </Route>
          <Route path="/:country/products" exact>
            <Landing />
          </Route>
          <Route path="/:country/Industries" exact>
            <Industries />
          </Route>
          <Route path="/:countryCode/products/:variant_id/:productId" exact>
            <ProductDetail />
          </Route>
          <Route path="/:countryCode/category/:categoryId" exact>
            <LandingCategorywise />
          </Route>
          <Route path="/:countryCode/products/:variant_id/:productId/checkoutPage" exact>

            {/* <Route path="/:country/products/:slug/checkoutPage" exact> */}
            <CheckoutPage />
          </Route>
          <Route path="/:country/contact" exact>
            <Contact />
          </Route>
          <Route path="/:country/Profile" exact>
            <Profile />
          </Route>
          <Route path="/:country/about" exact>
            <About />
          </Route>
          <Route path="/:country/login" exact>
            <Login />
          </Route>
          <Route path="/:country/signup" exact>
            <Signup />
          </Route>
          <Route path="/:country/cart" exact>
            <MyCart />
          </Route>

          {/* Fallback to home page if no route matches */}
          <Route path="*">
            <Redirect to={`/${countryCode}/index`} />
          </Route>
        </Switch>

        {/* Conditionally render Footer based on current route */}
        {showHeaderFooter && <Footer />}
      </Router>
    </AuthProvider>
  );
}

export default App;
